import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import isEmpty from '../../utils/isEmpty';
import Icon from '../Icon';
import styles from './InfoBox.module.scss';

const styledAddress = (address) =>
    address.split(',').map((item, i) => {
        return <p key={i}>{item}</p>;
    });

const googleMapsLink = (address) =>
    `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        address
    )}`;

const cleanTelephone = (telephone) => telephone.replace(/[^\d]/g, '');

const InfoBox = ({
    title,
    text,
    address,
    telephone,
    href,
    label,
    bookingHref,
    bookingLabel,
    modifiers=[],
}) => {
    if (
        isEmpty(title) &&
        isEmpty(text) &&
        isEmpty(address) &&
        isEmpty(telephone) &&
        isEmpty(href) &&
        isEmpty(label) &&
        isEmpty(address) &&
        isEmpty(bookingHref)
    ) {
        return null;
    }

    if (text) {
        text = text.split('</a>').join('<span></span></a>');
    }

    const classes = classNames(styles['InfoBox'], modifiers.map((mod) => styles['InfoBox--' + mod]))

    return (
        <section className={classes}>
            {title && <h2 className={styles['InfoBox__Title']}>{title}</h2>}
            {address && address.length > 2 && (
                <div className={styles['InfoBox__AddressWrapper']}>
                    <div className={styles['InfoBox__IconMargin']}>
                        <div className="InfoBox__AddressIconWrapper">
                            <Icon type="place" size="large" color="black800" />
                        </div>
                    </div>
                    <div className={styles['InfoBox__Address']}>
                        {styledAddress(address)}
                    </div>
                </div>
            )}
            {telephone && (
                <div className={styles['InfoBox__TelephoneWrapper']}>
                    <div className={styles['InfoBox__IconMargin']}>
                        <Icon type="phone" size="large" color="black800" />
                    </div>
                    <a
                        className={styles['InfoBox__Link']}
                        href={'tel:' + cleanTelephone(telephone)}>
                        {telephone}
                    </a>
                </div>
            )}

            {address && address.length > 2 && (
                <div className={styles['InfoBox__LinkWrapper']}>
                    <div className={styles['InfoBox__IconMargin']}>
                        <Icon type="external" size="large" color="black800" />
                    </div>
                    <a
                        className={styles['InfoBox__Link']}
                        href={googleMapsLink(address)}>
                        {i18n.t('EventPage.mapsLink')}
                    </a>
                </div>
            )}
            {href && (
                <div className={styles['InfoBox__LinkWrapper']}>
                    <div className={styles['InfoBox__IconMargin']}>
                        <Icon type="external" size="large" color="black800" />
                    </div>
                    <a className={styles['InfoBox__Link']} href={href}>
                        {label || href}
                    </a>
                </div>
            )}
            {!!bookingHref && (
                <div className={styles['InfoBox__LinkWrapper']}>
                    <div className={styles['InfoBox__IconMargin']}>
                        <Icon type="external" size="large" color="black800" />
                    </div>
                    <a className={styles['InfoBox__Link']} href={bookingHref}>
                        {bookingLabel || bookingHref}
                    </a>
                </div>
            )}
        </section>
    );
};

InfoBox.propTypes = {
    address: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string,
    href: PropTypes.string,
    label: PropTypes.string,
    telephone: PropTypes.string,
};

InfoBox.defaultProps = {
    address: '',
    text: '',
    link: '',
    title: '',
    href: '',
    label: '',
    telephone: '',
};

export default InfoBox;
